<template>
  <section>
    <div class="app-container">
      <Toolbar>
        <template v-slot:left>
          <Breadcrumb
            :home="home"
            :model="items"
            class="p-menuitem-text p-p-1"
          />
        </template>

        <template v-slot:right>
          <div class="p-inputgroup">
            <InputText
              v-model.trim="keyword"
              required="true"
              :class="{ 'p-invalid': submitted && !keyword }"
              placeholder="Search"
            />
            <Button
              icon="pi pi-search "
              class="p-button-primary p-mr-1"
              @click="loadSearchData"
            />
          </div>
          <Button
            icon="pi pi-plus"
            class="p-mr-2 p-button-success"
            @click="openDialog"
          />
        </template>
      </Toolbar>
      <div class="p-mt-2">
        <DataTable
          :value="lists"
          :lazy="true"
          :paginator="checkPagination"
          :rows="limit"
          :totalRecords="totalRecords"
          :scrollable="true"
          @page="onPage($event)"
          class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
        >
          <template #empty>
            <div class="p-text-center p-p-3">No records found</div>
          </template>
          <Column field="corporate_name" header="Corporate Head"></Column>
          <Column field="corporate_address" header="Address"></Column>
          <Column field="corporate_email" header="Email"></Column>
          <Column field="corporate_phone" header="Phone"></Column>
          <Column field="created_date" header="Added Date"></Column>
          <Column field="corporate_description" header="Description"></Column>
          <Column field="corporate_status" header="Status"></Column>
          <Column :exportable="false" header="Action">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-primary p-mr-2"
                @click="editIem(slotProps.data)"
              />
              <Button
                v-if="slotProps.data.corporate_status == 'Active'"
                icon="pi pi-eye-slash"
                class="p-button-rounded p-button-danger"
                @click="confirmChangeStatus(slotProps.data, 'Inactive')"
              />
              <Button
                v-else-if="slotProps.data.corporate_status == 'Inactive'"
                icon="pi pi-eye"
                class="p-button-rounded p-button-warning"
                @click="confirmChangeStatus(slotProps.data, 'Active')"
              />
            </template>
          </Column>
        </DataTable>
      </div>

      <Dialog
        v-model:visible="productDialog"
        :style="{ width: '50vw' }"
        :maximizable="true"
        position="top"
        class="p-fluid"
      >
        <template #header>
          <h4 class="p-dialog-titlebar p-dialog-titlebar-icon">
            {{ dialogTitle }}
          </h4>
        </template>
        <div class="p-field">
          <label for="name">Corporate Name</label>
          <InputText
            id="name"
            v-model.trim="product.name"
            required="true"
            autofocus
            :class="{ 'p-invalid': submitted && !product.name }"
          />
          <small class="p-invalid" v-if="submitted && !product.name"
            >Corporate Name is required.</small
          >
        </div>
        <div class="p-field">
          <label for="corporateaddress">Corporate Address</label>
          <InputText id="corporateaddress" v-model.trim="product.address" />
        </div>
        <div class="p-field">
          <label for="corporatephone">Corporate Phone</label>
          <InputText id="corporatephone" v-model.trim="product.phone" />
        </div>
        <div class="p-field">
          <label for="corporateemail">Corporate Email</label>
          <InputText id="corporateemail" v-model.trim="product.email" />
        </div>
        <div class="p-field">
          <label for="corporatedescription">Corporate Description</label>
          <InputText
            id="corporatedescription"
            v-model.trim="product.description"
          />
        </div>
        <template #footer>
          <Button
            type="submit"
            label="Save"
            icon="pi pi-check"
            class="p-button-primary"
            @click="saveItem"
          />
        </template>
      </Dialog>

      <Dialog
        v-model:visible="statusDialog"
        :style="{ width: '450px' }"
        header="Confirm"
      >
        <div class="confirmation-content">
          <i
            class="pi pi-exclamation-triangle p-mr-3"
            style="font-size: 2rem"
          />
          <span v-if="product">
            Change the status of <b>{{ product.name }}</b> to
            {{ productStatus }}?</span
          >
        </div>
        <template #footer>
          <Button
            label="No"
            icon="pi pi-times"
            class="p-button-text"
            @click="statusDialog = false"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            class="p-button-text"
            @click="changeStatus"
          />
        </template>
      </Dialog>
    </div>
  </section>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import CorporateHead from "../../service/CorporateHead";
import Toaster from "../../helpers/Toaster";

@Options({
  components: {},
})
export default class CorporatesHead extends Vue {
  private imagePath = "";
  private lists = [];
  private corporatehead;
  private productStatus = "";
  private keyword = "";
  private productDialog = false;
  private checkPagination = true;
  private statusDialog = false;
  private totalRecords = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Corporates Head" },
  ];
  private toast;
  private product = {
    id: 0,
    name: "",
    address: "",
    phone: "",
    email: "",
    description: "",
    status: "",
  };

  private dialogTitle;
  private dialogCallback;
  private submitted = false;

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }
  // CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }

  created() {
    this.corporatehead = new CorporateHead();
    this.imagePath = this.corporatehead.getBaseURL() + "uploads/services/";
    this.toast = new Toaster();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.product = {
      id: 0,
      name: "",
      address: "",
      phone: "",
      email: "",
      description: "",
      status: "",
    };

    this.submitted = false;
    this.dialogTitle = "Add New Corporates Head";
    this.productDialog = true;
  }

  //CLOSE THE ITEM DAILOG BOX
  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(e) {
    e.preventDefault();

    this.submitted = true;
    if (this.product.name.trim()) {
      if (this.product.id != 0) {
        this.corporatehead.updateItem(this.product).then((res) => {
          this.loadList(0);
          this.toast.handleResponse(res);
        });
      } else {
        this.corporatehead.saveItem(this.product).then((res) => {
          this.loadList(0);
          this.toast.handleResponse(res);
        });
      }

      this.productDialog = false;
      this.product = {
        id: 0,
        name: "",
        address: "",
        phone: "",
        email: "",
        description: "",
        status: "",
      };
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.submitted = false;
    this.dialogTitle = "Update Corporate Head";
    this.productDialog = true;

    this.corporatehead.getItem(data).then((res) => {
      if (res.length > 0) {
        this.product = {
          id: res[0].corporate_id,
          name: res[0].corporate_name,
          address: res[0].corporate_address,
          phone: res[0].corporate_phone,
          email: res[0].corporate_email,
          description: res[0].corporate_description,
          status: res[0].corporate_status,
        };
      }
    });
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmChangeStatus(data, status) {
    this.product = {
      id: data.corporate_id,
      name: data.corporate_name,
      address: data.corporate_address,
      phone: data.corporate_phone,
      email: data.corporate_email,
      description: data.corporate_description,
      status: data.corporate_status,
    };
    this.productStatus = status;
    this.statusDialog = true;
  }

  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  changeStatus() {
    this.statusDialog = false;
    this.product.status = this.productStatus;
    this.corporatehead.changeStatus(this.product).then((res) => {
      this.loadList(0);
      this.toast.handleResponse(res);
    });
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.corporatehead.getItems(page).then((data) => {
      this.lists = data.corporate_record;
      this.totalRecords = data.total_head_count;
      this.limit = data.limit_per_page;
    });
  }

  loadSearchData() {
    this.submitted = true;
    if (this.keyword) {
      this.corporatehead.getSearchedCorpHead(this.keyword).then((data) => {
        this.lists = data.corporate_record;
        this.checkPagination = data.pagination;
      });
    }
  }
}
</script>

<style scoped>
.product-image {
  width: 30px;
  height: auto;
}
</style>