<template>
  <section>
    <div class="app-container">
      <Toolbar>
        <template v-slot:left>
          <Breadcrumb
            :home="home"
            :model="items"
            class="p-menuitem-text p-p-1"
          />
        </template>

        <template v-slot:right>
          <div class="p-inputgroup">
            <InputText
              v-model.trim="keyword"
              required="true"
              :class="{ 'p-invalid': submitted && !keyword }"
              placeholder="Search"
            />
            <Button
              icon="pi pi-search "
              class="p-button-primary p-mr-1"
              @click="loadSearchData"
            />
          </div>
          <div class="p-mx-2">
            <Button
              icon="pi pi-plus"
              class="p-button-success"
              @click="openDialog"
            />
          </div>
        </template>
      </Toolbar>
      <div class="p-mt-2">
        <DataTable
          :value="lists"
          :lazy="true"
          :paginator="checkPagination"
          :rows="limit"
          :totalRecords="totalRecords"
          :scrollable="true"
          @page="onPage($event)"
          class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
        >
          <template #empty>
            <div class="p-text-center p-p-3">No records found</div>
          </template>
          <Column
            field="name"
            header="Name"
            headerStyle="width: 150px"
          ></Column>
          <Column
            field="percentage"
            header="Percentage"
            headerStyle="width: 150px"
          ></Column>
          <Column
            field="phone"
            header="Phone"
            headerStyle="width: 150px"
          ></Column>
          <Column
            field="email"
            header="Email"
            headerStyle="width: 250px"
          ></Column>
          <Column
            field="province_name"
            header="Province"
            headerStyle="width: 150px"
          ></Column>
          <Column
            field="region_name"
            header="Region"
            headerStyle="width: 150px"
          ></Column>
          <Column
            field="city"
            header="City"
            headerStyle="width: 150px"
          ></Column>
          <Column
            field="created_date"
            header="Created"
            headerStyle="width: 150px"
          ></Column>
          <Column
            field="status"
            header="Status "
            headerStyle="width: 150px"
          ></Column>
          <Column
            :exportable="false"
            header="Action "
            headerStyle="width: 150px"
          >
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-primary p-mr-2"
                @click="editIem(slotProps.data)"
              />
              <Button
                v-if="slotProps.data.status == 'Active'"
                icon="pi pi-eye-slash"
                class="p-button-rounded p-button-danger"
                @click="confirmChangeStatus(slotProps.data, 'Inactive')"
              />
              <Button
                v-else-if="slotProps.data.status == 'Inactive'"
                icon="pi pi-eye"
                class="p-button-rounded p-button-warning"
                @click="confirmChangeStatus(slotProps.data, 'Active')"
              />
            </template>
          </Column>
        </DataTable>
      </div>

      <Dialog
        v-model:visible="productDialog"
        :style="{ width: '50vw' }"
        :maximizable="true"
        position="top"
        class="p-fluid"
      >
        <template #header>
          <h4 class="p-dialog-titlebar p-dialog-titlebar-icon">
            {{ dialogTitle }}
          </h4>
        </template>
        <div class="p-field">
          <label for="drycleaner">Dry Cleaner:</label>
          <InputText
            id="drycleaner"
            v-model.trim="product.name"
            required="true"
            placeholder="Enter dry cleaner"
            autofocus
            :class="{ 'p-invalid': submitted && !product.name }"
          />
          <small class="p-invalid" v-if="submitted && !product.region"
            >Dry Cleaner is required.</small
          >
        </div>
        <div class="p-field">
          <label for="percentage:">Percentage::</label>
          <InputNumber
            id="percentage:"
            v-model="product.percentage"
            mode="currency"
            currency="USD"
            locale="en-US"
          />
        </div>
        <div class="p-field">
          <label for="phone">Phone:</label>
          <InputText id="phone" v-model.trim="product.phone" />
        </div>
        <div class="p-field">
          <label for="email">Email:</label>
          <InputText id="email" v-model.trim="product.email" />
        </div>
        <div class="p-field">
          <label for="region">Region:</label>
          <Dropdown
            v-model="product.region"
            :options="allRegions"
            :filter="true"
            placeholder="Select a region"
          >
            <template #value="slotProps">
              <span>
                Province: {{ slotProps.value.province_name }} | Region:
                {{ slotProps.value.region_name }}
              </span>
            </template>
            <template #option="slotProps">
              <span>
                {{ slotProps.option.province_name }} |
                {{ slotProps.option.region_name }}
              </span>
            </template>
          </Dropdown>
        </div>
        <div class="p-field">
          <label for="address">Address:</label>
          <InputText id="address" v-model.trim="product.address" />
        </div>
        <div class="p-field">
          <label for="city">City:</label>
          <InputText id="city" v-model.trim="product.city" />
        </div>
        <div class="p-field">
          <label for="description">Description:</label>
          <InputText id="description" v-model.trim="product.description" />
        </div>
        <template #footer>
          <Button
            type="submit"
            label="Save"
            icon="pi pi-check"
            class="p-button-primary"
            @click="saveItem"
          />
        </template>
      </Dialog>

      <Dialog
        v-model:visible="statusDialog"
        :style="{ width: '450px' }"
        header="Confirm"
      >
        <div class="confirmation-content">
          <i
            class="pi pi-exclamation-triangle p-mr-3"
            style="font-size: 2rem"
          />
          <span v-if="product"
            >Change the status of <b>{{ product.name }}</b> to
            {{ productStatus }}?</span
          >
        </div>
        <template #footer>
          <Button
            label="No"
            icon="pi pi-times"
            class="p-button-text"
            @click="statusDialog = false"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            class="p-button-text"
            @click="changeStatus"
          />
        </template>
      </Dialog>
    </div>
  </section>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import DryCleaner from "../../service/DryCleaner";
import Toaster from "../../helpers/Toaster";

@Options({
  components: {},
})
export default class DryCleaners extends Vue {
  private imagePath = "";
  private lists = [];
  private drycleaners;
  private allRegions;
  private productStatus = "";
  private keyword = "";
  private checkPagination = true;
  private productDialog = false;
  private statusDialog = false;
  private totalRecords = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Dry Cleaners" },
  ];
  private toast;

  private product = {
    id: 0,
    name: "",
    percentage: "",
    phone: "",
    email: "",
    region: "",
    address: "",
    city: "",
    description: "",
    status: "",
  };

  private selectedRegion;
  private selectedRegionObj;

  private dialogTitle;
  private dialogCallback;
  private submitted = false;

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.drycleaners = new DryCleaner();
    this.toast = new Toaster();
    this.imagePath = this.drycleaners.getBaseURL() + "uploads/services/";
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.product = {
      id: 0,
      name: "",
      percentage: "",
      phone: "",
      email: "",
      region: "",
      address: "",
      city: "",
      description: "",
      status: "",
    };

    this.submitted = false;
    this.dialogTitle = "Add New Region";
    this.productDialog = true;
  }

  //CLOSE THE ITEM DAILOG BOX
  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(e) {
    e.preventDefault();

    this.submitted = true;
    if (this.product.name.trim()) {
      if (this.product.id != 0) {
        this.drycleaners.updateItem(this.product).then((res) => {
          this.loadList(0);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      } else {
        this.drycleaners.saveItem(this.product).then((res) => {
          this.loadList(0);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      }

      this.productDialog = false;
      this.product = {
        id: 0,
        name: "",
        percentage: "",
        phone: "",
        email: "",
        region: "",
        address: "",
        city: "",
        description: "",
        status: "",
      };
    }
  }

  //SET DROPDOWN DATA IN EDIT FORM
  setDropDownData() {
    this.allRegions.filter((elem) => {
      if (elem.region_id == this.selectedRegion) {
        this.selectedRegionObj = elem;
      }
    });
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.submitted = false;
    this.dialogTitle = "Update Dry Cleaner";
    this.productDialog = true;
    this.drycleaners.getItem(data).then((res) => {
      if (res.length > 0) {
        this.selectedRegion = res[0].region_id;
        this.setDropDownData();

        this.product = {
          id: res[0].dry_clearner_id,
          name: res[0].name,
          percentage: res[0].percentage,
          phone: res[0].phone,
          email: res[0].email,
          region: this.selectedRegionObj,
          address: res[0].address,
          city: res[0].city,
          description: res[0].description,
          status: res[0].status,
        };
      }
    });
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmChangeStatus(data, status) {
    this.product = {
      id: data.dry_clearner_id,
      name: data.name,
      percentage: "",
      phone: "",
      email: "",
      region: "",
      address: "",
      city: "",
      description: "",
      status: data.status,
    };
    this.productStatus = status;
    this.statusDialog = true;
  }

  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  changeStatus() {
    this.statusDialog = false;
    this.product.status = this.productStatus;
    this.drycleaners.changeStatus(this.product).then((res) => {
      this.loadList(0);
      //SHOW NOTIFICATION
      this.toast.handleResponse(res);
    });
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.drycleaners.getItems(page).then((data) => {
      this.lists = data.dry_cleaners;
      this.allRegions = data.regions;
      this.totalRecords = data.total_drycleaner_count;
      this.limit = data.limit_per_page;
    });
  }

  loadSearchData() {
    this.submitted = true;
    if (this.keyword) {
      this.drycleaners.getSearchedDryCleaners(this.keyword).then((data) => {
        this.lists = data.dry_cleaners;
        this.allRegions = data.regions;
        this.checkPagination = data.pagination;
      });
    }
  }
}
</script>

<style scoped>
.product-image {
  width: 30px;
  height: auto;
}
</style>