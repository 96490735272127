<template>
  <section>
    <div class="app-container">
      <Toolbar>
        <template v-slot:left>
          <Breadcrumb
            :home="home"
            :model="items"
            class="p-menuitem-text p-p-1"
          />
        </template>

        <template v-slot:right>
          <div class="p-inputgroup">
            <InputText
              v-model.trim="keyword"
              required="true"
              :class="{ 'p-invalid': submitted && !keyword }"
              placeholder="Search"
            />
            <Button
              icon="pi pi-search "
              class="p-button-primary p-mr-1"
              @click="loadList"
            />
          </div>
          <div class="p-mx-2">
            <Button
              icon="pi pi-plus"
              class="p-mr-2 p-button-success"
              @click="openDialog"
            />
          </div>
        </template>
      </Toolbar>
      <div class="p-mt-2">
        <DataTable
          :value="lists"
          :lazy="false"
          :paginator="false"
          :scrollable="true"
          class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
        >
          <Column
            field="customer_id"
            headerStyle="width: 150px"
            header="Customer ID"
          ></Column>
          <Column field="first_name" headerStyle="width: 150px" header="Name">
            <template #body="slotProps">
              {{ slotProps.data.first_name }} {{ slotProps.data.last_name }}
            </template>
          </Column>
          <Column
            field="contact"
            headerStyle="width: 150px"
            header="Contact"
          ></Column>
          <Column field="phone" headerStyle="width: 150px" header="Phone">
          </Column>
          <Column
            field="gender"
            headerStyle="width: 150px"
            header="Gender"
          ></Column>
          <Column field="email" headerStyle="width: 300" header="Email">
          </Column>
          <Column
            field="branch_id"
            headerStyle="width: 150px"
            header="Store No"
          >
          </Column>
          <Column field="status" headerStyle="width: 150px" header="Status">
          </Column>
          <Column
            :exportable="false"
            headerStyle="width: 200px"
            header="Action"
          >
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-primary p-mr-2"
                @click="editIem(slotProps.data)"
              />
              <Button
                v-if="slotProps.data.status == 'Active'"
                icon="pi pi-eye-slash"
                class="p-button-rounded p-button-danger"
                @click="confirmChangeStatus(slotProps.data, 'Inactive')"
              />
              <Button
                v-else-if="slotProps.data.status == 'Inactive'"
                icon="pi pi-eye"
                class="p-button-rounded p-button-warning"
                @click="confirmChangeStatus(slotProps.data, 'Active')"
              />
            </template>
          </Column>
        </DataTable>
      </div>

      <Dialog
        v-model:visible="statusDialog"
        :style="{ width: '450px' }"
        header="Confirm"
      >
        <div class="confirmation-content">
          <i
            class="pi pi-exclamation-triangle p-mr-3"
            style="font-size: 2rem"
          />
          <span v-if="customers">
            Change the status of <b>{{ customers.firstName }}</b> to
            {{ productStatus }}?</span
          >
        </div>
        <template #footer>
          <Button
            label="No"
            icon="pi pi-times"
            class="p-button-text"
            @click="statusDialog = false"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            class="p-button-text"
            @click="changeStatus"
          />
        </template>
      </Dialog>
      <CustomerDialog
        :customerDetail="{
          status: customerStatus,
          customerId: customers.customerId,
          permission: permission,
          title: title,
        }"
        v-on:updateCustomerStatus="updateCustomerStatus"
      />
    </div>
  </section>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Customer from "../../service/Customer";
import Toaster from "../../helpers/Toaster";
import moment from "moment";
import CustomerDialog from "../../components/CustomerDialog.vue";
@Options({
  components: {
    CustomerDialog,
  },
})
export default class Customers extends Vue {
  private lists = [];
  private keyword = "";
  private customer;
  private toast;
  private submitted = false;
  private showPin = false;
  private productStatus = "";
  private permission = "yes";
  private title = "Add Customer";
  private customerStatus = "";
  private statusDialog = false;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Customer" },
  ];

  private customers = {
    customerId: "",
    firstName: "",
    status: "Active",
  };

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.customer = new Customer();
    this.toast = new Toaster();
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmChangeStatus(data, status) {
    this.customers.customerId = data.customer_id;
    this.customers.firstName = data.first_name;

    this.productStatus = status;
    this.statusDialog = true;
  }

  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  changeStatus() {
    this.statusDialog = false;
    this.customers.status = this.productStatus;
    this.customer.changeStatus(this.customers).then((res) => {
      this.loadList();
      //SHOW NOTIFICATION
      this.toast.handleResponse(res);
    });
  }

  //FETCH THE DATA FROM SERVER
  loadList() {
    this.submitted = true;
    if (this.keyword) {
      this.customer.getItems(this.keyword).then((data) => {
        this.lists = data.search_result;
      });
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.customers.customerId = data.customer_id;
    this.customerStatus = "updateCustomer";
    this.title = "Update Customer";
  }

  //USED TO UPDATE THE PIN STATUS
  updateCustomerStatus() {
    this.customerStatus = "";
    this.loadList();
  }

  //USED TO OPEN THE ADD DIALOG MODEL
  openDialog() {
    this.customerStatus = "openCustomer";
    this.title = "Add Customer";
  }
}
</script>