<template>
  <section>
    <div class="app-container">
      <div class="row">
        <div class="col-md-12 p-p-1">
          <div class="text-primary">
            <span style="font-size: 24px">
              <i style="fontsize: 1.5rem" class="pi pi-home"></i> Dashboard
              {{ branchName }}
            </span>
            <!-- <Button class="p-button-primary pull-right" @click="openSearchForm" icon="pi pi-filter" /> -->
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2 col-sm-12 float-left p-p-1">
          <div class="card">
            <div class="card-body p-2">
              <div style="float: left; width: 60%">
                <label class="mb-1"> Avg Sale </label>
                <div class="">
                  <h4 class="mt-2 p-mb-2">{{ avgValue }}</h4>
                </div>
              </div>
              <div style="float: left; width: 40%; text-align: center">
                <img
                  src="@/assets/menu-icons/buyback.png"
                  class="dash-icons-style"
                  alt=""
                />
              </div>
            </div>
            <div class="card-footer p-2">
              <div class="float-left">
                <span class="primary-color"> MTD </span>
              </div>
              <div class="float-right">
                <b class="primary-color"> {{ avgValueMtd }} </b>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2 col-sm-12 float-left p-p-1">
          <div class="card">
            <div class="card-body p-2">
              <div style="float: left; width: 60%">
                <label class="mb-1"> On Time % </label>
                <div class="">
                  <h4 class="mt-2 p-mb-2">{{ onTime }}</h4>
                </div>
              </div>
              <div style="float: left; width: 40%; text-align: center">
                <img
                  src="@/assets/menu-icons/redo.png"
                  class="dash-icons-style"
                  alt=""
                />
              </div>
            </div>
            <div class="card-footer p-2">
              <div class="float-left">
                <span class="primary-color"> MTD </span>
              </div>
              <div class="float-right">
                <b class="primary-color"> {{ onTimeMtd }} </b>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2 col-sm-12 float-left p-p-1">
          <div class="card">
            <div class="card-body p-2">
              <div style="float: left; width: 60%">
                <label class="mb-1"> Rush Services </label>
                <div class="">
                  <h4 class="mt-2 p-mb-2">{{ rushService }}</h4>
                </div>
              </div>
              <div style="float: left; width: 40%; text-align: center">
                <img
                  src="@/assets/menu-icons/pricelist.png"
                  class="dash-icons-style"
                  alt=""
                />
              </div>
            </div>
            <div class="card-footer p-2">
              <div class="float-left">
                <span class="primary-color"> MTD </span>
              </div>
              <div class="float-right">
                <b class="primary-color"> {{ rushServiceMtd }} </b>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2 col-sm-12 float-left p-p-1">
          <div class="card">
            <div class="card-body p-2">
              <div style="float: left; width: 60%">
                <label class="mb-1"> Total Sales </label>
                <div class="">
                  <h4 class="mt-2 p-mb-2">{{ totalInvAmt }}</h4>
                </div>
              </div>
              <div style="float: left; width: 40%; text-align: center">
                <img
                  src="@/assets/menu-icons/branch.png"
                  class="dash-icons-style"
                  alt=""
                />
              </div>
            </div>
            <div class="card-footer p-2">
              <div class="float-left">
                <span class="primary-color"> MTD </span>
              </div>
              <div class="float-right">
                <b class="primary-color"> {{ totalInvAmtMtd }} </b>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2 col-sm-12 float-left p-p-1">
          <div class="card">
            <div class="card-body p-2">
              <div style="float: left; width: 60%">
                <label class="mb-1"> Labour Cost </label>
                <div class="">
                  <h4 class="mt-2 p-mb-2">{{ totalLabourCost }}</h4>
                </div>
              </div>
              <div style="float: left; width: 40%; text-align: center">
                <img
                  src="@/assets/menu-icons/labour.png"
                  class="dash-icons-style"
                  alt=""
                />
              </div>
            </div>
            <div class="card-footer p-2">
              <div class="float-left">
                <span class="primary-color"> MTD </span>
              </div>
              <div class="float-right">
                <b class="primary-color"> {{ getTotalLabourCostMtd }} </b>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2 col-sm-12 float-left p-p-1">
          <div class="card">
            <div class="card-body p-2">
              <div style="float: left; width: 60%">
                <label class="mb-1"> Store Magic </label>
                <div class="">
                  <h4 class="mt-2 p-mb-2">{{ storeMagic }}</h4>
                </div>
              </div>
              <div style="float: left; width: 40%; text-align: center">
                <img
                  src="@/assets/menu-icons/thunder.png"
                  class="dash-icons-style"
                  alt=""
                />
              </div>
            </div>
            <div class="card-footer p-2">
              <div class="float-left">
                <span class="primary-color"> MTD </span>
              </div>
              <div class="float-right">
                <b class="primary-color"> {{ storeMagicMtd }} </b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8 col-sm-12 float-left p-p-1">
          <div class="card">
            <div class="card-header">
              <h5>
                ($) Projected Sales
                <small>
                  ( From {{ formatDate(date1) }} - TO -
                  {{ formatDate(date2) }})</small
                >
                <i class="pull-right">
                  Left For Today
                  <label
                    style="
                      background-color: #c00;
                      color: #fff;
                      border-radius: 2px;
                    "
                    class="p-p-1 p-m-0"
                  >
                    $ {{ leftToday }}
                  </label></i
                >
              </h5>
            </div>
            <div class="card-body p-p-1">
              <Chart
                type="bar"
                :data="multiAxisData"
                :options="multiAxisOptions"
                :height="130"
              />
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-12 float-left p-p-1">
          <div class="card">
            <div
              class="card-header"
              style="background-color: #f75656; color: #fff"
            >
              <h5>
                <a style="color: #fff" href=""> Announcements </a>
                <small class="pull-right">
                  <i class="pi pi-calendar"></i> Today ({{ announceToday }})
                </small>
              </h5>
            </div>
            <div class="card-body p-p-0">
              <div class="browser-stats">
                <ul class="list-unstyled">
                  <li
                    class="p-pl-4 p-pr-4 p-pt-3 p-pb-3 border-bottom"
                    v-for="anc in announcements"
                    :key="anc"
                  >
                    <img
                      style="width: 20px; height: auto"
                      :src="
                        announcePath + getAnnouncemnetIcon(anc.notification)
                      "
                      alt="User Avatar"
                      class="rounded-circle p-mr-1"
                    />
                    <a @click="announcementDialog(anc.annoucementId)" href="javascript:void(0)">
                      {{ anc.title }}
                    </a>
                    <div class="text-success pull-right">
                      <small class="text-muted pull-right">
                        <i class="pi pi-clock"></i> {{checkTimePassed(anc.createdDate+' '+anc.time).toUpperCase()}}
                      </small>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 col-sm-12 float-left p-p-1">
          <div class="card">
            <div class="card-body">
              <div class="media-body">
                <h6 class="p-mb-2">BUYBACKS</h6>
                <h3 class="p-mb-0 text-muted">
                  <span class="text-primary">$ {{ buybackAmount }}</span>
                  <span class="pull-right">
                    <small> MTD </small> $ {{ buybackAmountMtd }}
                  </span>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-12 float-left p-p-1">
          <div class="card">
            <div class="card-body">
              <div class="media-body">
                <h6 class="p-mb-2">REDOS</h6>
                <h3 class="p-mb-0 text-muted">
                  <span class="text-primary"> $ {{ redoInvoice }} </span>
                  <span class="pull-right">
                    <small> MTD </small> {{ redoInvoiceMtd }}
                  </span>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-12 float-left p-p-1">
          <div class="card">
            <div class="card-body">
              <div class="media-body">
                <h6 class="p-mb-2">REFUNDS</h6>
                <h3 class="p-mb-0 text-muted">
                  <span class="text-primary"> $ {{ refundAmount }} </span>
                  <span class="pull-right">
                    <small> MTD </small> $ {{ refundAmountMtd }}
                  </span>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-12 float-left p-p-1">
          <div class="card">
            <div class="card-body">
              <div class="media-body">
                <h6 class="p-mb-2">NO OF RACK</h6>
                <h3 class="p-mb-0 text-muted">
                  <span class="text-primary"> {{ getItemsRack }} </span>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-12 float-left p-p-1">
          <div class="card">
            <div class="card-body">
              <div class="media-body">
                <h6 class="p-mb-2">NO OF PENDING</h6>
                <h3 class="p-mb-0 text-muted">
                  <span class="text-primary"> {{ getItemsPending }} </span>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-12 float-left p-p-1">
          <div class="card">
            <div class="card-body">
              <div class="media-body">
                <h6 class="p-mb-2">NO OF READY</h6>
                <h3 class="p-mb-0 text-muted">
                  <span class="text-primary"> {{ getItemsReady }} </span>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-12 float-left p-p-1">
          <div class="card">
            <div class="card-body">
              <div class="media-body">
                <h6 class="p-mb-2">NO OF ASSOCIATES</h6>
                <h3 class="p-mb-0 text-muted">
                  <span class="text-primary"> {{ getTotalEmployee }} </span>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-12 float-left p-p-1">
          <div class="card">
            <div class="card-body">
              <div class="media-body">
                <h6 class="p-mb-2">EMP WORK HRS</h6>
                <h3 class="p-mb-0 text-muted">
                  <span class="text-primary"> {{ getEmployeeHrs }} </span>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-12 float-left p-p-1">
          <div class="card">
            <div class="card-body">
              <div class="media-body">
                <h6 class="p-mb-2">DRY CLEANING</h6>
                <h3 class="p-mb-0 text-muted">
                  <span class="text-primary"> {{ dryCleaning }} </span>
                  <span class="pull-right">
                    <small> MTD </small> {{ dryCleaningMtd }}
                  </span>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-12 float-left p-p-1">
          <div class="card">
            <div class="card-body">
              <div class="media-body text-left">
                <h6 class="p-mb-2">
                  <span class="text-primary"> SALES YTD </span>
                  <span class="pull-right"> SALES LY </span>
                </h6>
                <h3 class="p-mb-0 text-muted">
                  <span class="text-primary">
                    <small> YTD </small> $ {{salesYtdly[0]}}
                  </span>
                  <span class="pull-right">
                    <small> LY ${{salesYtdly[1]}} </small>
                  </span>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-12 float-left p-p-1">
          <div class="card">
            <div class="card-body">
              <div class="media-body text-left">
                <h6 class="p-mb-2">
                  <span class="text-primary"> SALES QTD </span>
                  <span class="pull-right"> LY QTD ( TODAY {{salesQtr[2]}})</span>
                </h6>
                <h3 class="p-mb-0 text-muted">
                  <span class="text-primary">
                    <small> QTD </small> $ {{ salesQtr[0] }}
                  </span>
                  <span class="pull-right">
                    <small> LY ${{ salesQtr[1] }} </small>
                  </span>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12 float-left p-p-1">
          <div class="card">
            <div class="card-header">
              <h5>
                <i class="pi pi-star-o"></i>
                Top 5 Services
              </h5>
            </div>
            <div class="card-body p-p-0">
              <ul class="list-unstyled">
                 <template v-for="(service,index) in topServicesLabel" :key="service">
                  <li class="pl-4 pr-4 pt-3 pb-3 border-bottom" v-if="index <= 4" >
                    <span style="font-size: 20px; margin-right: 15px"> {{index+1}} </span>
                    {{service}}
                    <label class="pull-right">${{formatAmount(topServicesAmount[index])}}</label>
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12 float-left p-p-1">
          <div class="card">
            <div class="card-header">
              <h5>
                <i class="pi pi-star-o"></i>
                Top 5 Departments
              </h5>
            </div>
            <div class="card-body p-p-0">
              <ul class="list-unstyled">
                <template v-for="(depart,index) in getTopDepartment" :key="depart">
                <li class="pl-4 pr-4 pt-3 pb-3 border-bottom" v-if="index <= 4" >
                  <span style="font-size: 20px; margin-right: 15px"> {{depart.no}} </span>
                  {{depart.department}}
                  <label class="pull-right">${{formatAmount(depart.total)}}</label>
                </li>
                </template>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 float-left p-p-1 col-sm-12">
          <div class="card">
            <div class="card-header">
              <h5>
                <i class="pi pi-chart-bar"></i> Store Activities & Analytics
                <small> ( From {{formatDate(date1)}} - TO - {{formatDate(date2)}}) </small>
              </h5>
            </div>
            <div class="card-body p-p-0"></div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 float-left p-p-1 col-sm-12">
          <div class="card">
            <div class="card-header">
              <h5>($) Departments Sold</h5>
            </div>
            <div class="card-body p-p-1">
              <Chart
                type="bar"
                :data="departmentChart"
                :options="storeActivityChartOptions"
                :height="220"
              />
            </div>
          </div>
        </div>
        <div class="col-md-4 float-left p-p-1 col-sm-12">
          <div class="card">
            <div class="card-header">
              <h5>($) Services Sold</h5>
            </div>
            <div class="card-body p-p-1">
              <Chart
                type="bar"
                :data="serviceChartData"
                :options="storeActivityChartOptions"
                :height="220"
              />
            </div>
          </div>
        </div>
        <div class="col-md-4 float-left p-p-1 col-sm-12">
          <div class="card">
            <div class="card-header">
              <h5>Customer Visits</h5>
            </div>
            <div class="card-body p-p-1">
              <Chart
                type="bar"
                :data="visitings"
                :options="storeActivityChartOptions"
                :height="220"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Dialog
    v-model:visible="searchDialog"
    :style="{ width: '50vw' }"
    :maximizable="true"
    position="top"
    class="p-fluid"
  >
    <template #header>
      <h4 class="p-dialog-titlebar p-dialog-titlebar-icon">  Filter Stores</h4>
    </template>
    <div class="p-field p-mt-2">
      <label>Dates</label>
      <select class="form-control" v-model="searchForm.filter">
        <option value="0">None</option>
        <option value="Today">Today</option>
        <option value="This_Week">This Week</option>
        <option value="This_Month">This Month</option>
        <option value="Last_Month">Last Month</option>
        <option value="This_Year">This Year</option>
      </select>
    </div>
    <div class="p-field p-mt-2">
      <label for="name">OR Date From:</label>
      <input
        type="date"
        v-model.trim="searchForm.date1"
        autofocus
        class="form-control"
      />
    </div>
    <div class="p-field p-mt-2">
      <label for="name">Date To:</label>
      <input
        type="date"
        v-model.trim="searchForm.date2"
        autofocus
        class="form-control"
      />
    </div>
    <div class="p-field p-mt-2" v-if="activeType != 'Branch'">
      <label>Filter Stores</label>
      <Dropdown v-model="searchForm.assignedBranchId" :options="myStores" optionLabel="mall" :filter="true" placeholder="Select a Store" />
    </div>
    <template #footer>
      <Button
        type="submit"
        label="Search"
        icon="pi pi-search"
        class="p-button-primary"
        @click="searchDashboard()"
      />
    </template>
  </Dialog>
  <AnnouncementDialog
    :announceDetail="{
      status: announceStatus,
      annoucementId:  annoucementId,
    }"
    v-on:updateAnnounceStatus="updateAnnounceStatus"
  />
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import HomepageService from "../../service/HomepageService";
import Toaster from "../../helpers/Toaster";
import moment from "moment";
import { camelCase } from "lodash";
import {branchDetail} from "../../pages/announcements/IAnnounce";
import AnnouncementDialog from "../../components/AnnouncementDialog.vue";

@Options({
  components: {AnnouncementDialog},
})
export default class Homepage extends Vue {
  private searchDialog = false;
  private activeType = '';
  private myStores: Array<branchDetail> = [];
  private announcePath = '';
  private date1 = '';
  private date2 = '';
  private searchDate = '';
  private branchName = '';
  private announceToday = 0;
  private avgValue = "$ 0.00";
  private avgValueMtd = "$ 0.00";
  private buybackAmount = "0.00";
  private buybackAmountMtd = "0.00";
  private dryCleaning = "$ 0.00";
  private dryCleaningMtd = "$ 0.00";
  private getEmployeeHrs = 0;
  private getItemsPending = 0;
  private getItemsRack = 0;
  private getItemsReady = 0;
  private getTotalEmployee = 8;
  private getTotalLabourCostMtd = "$ 0.00";
  private leftToday =  "0.00";
  private onTime =  "0.00 %";
  private onTimeMtd =  "0.00 %";
  private redoInvoice =  "0.00";
  private redoInvoiceMtd =  "0.00";
  private refundAmount =  "0.00";
  private refundAmountMtd =  "0.00";
  private rushService =  "# 0";
  private rushServiceMtd =  "# 0";
  private announcements =  [];
  private salesQtr =  [];
  private salesYtdly =  [];
  private topServicesLabel =  [];
  private topServicesAmount =  [];
  private customersVisitings =  [];
  private departLabel  =  [];
  private departTotals =  [];
  private actualData =  [];
  private planData =  [];
  private getTopDepartment =  [];
  private storeMagic =  "$ 0.00";
  private storeMagicMtd =  "0.00";
  private totalInvAmt =  "$ 0.00";
  private totalInvAmtMtd = "$ 0.00";
  private totalLabourCost = "$ 0.00";
  private homepageTxn;
  private toast;
  private searchForm = {
    date1: "",
    date2: "",
    filter: "",
    assignedBranchId: {
      code: '',
      mall: ''
    },
  };


  private multiAxisOptions = {
    responsive: true,
    tooltips: {
      mode: "index",
      intersect: true,
    },
    scales: {
      yAxes: [
        {
          type: "linear",
          display: true,
          position: "left",
          id: "y-axis-1",
        },
        {
          type: "linear",
          display: true,
          position: "right",
          id: "y-axis-2",
          gridLines: {
            drawOnChartArea: false,
          },
        },
      ],
    },
  };

  private storeActivityChartOptions = {
    responsive: true,
    tooltips: {
      mode: "index",
      intersect: true,
    },
    scales: {
      yAxes: [
        {
          type: "linear",
          display: true,
          position: "left",
          id: "y-axis-1",
        }
      ],
    },
  };
  private announceStatus = "";
  private annoucementId = "";

  //OPEN ANNOUNCEMENT DIALOG BOX
  announcementDialog(id) {
    this.annoucementId = id;
    this.announceStatus = "updateAnnouncement";
  }

  //USED TO UPDATE THE PIN STATUS
  updateAnnounceStatus() {
    this.announceStatus = "";
    this.getHomepageDetails();
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.homepageTxn = new HomepageService();
    this.announcePath = this.homepageTxn.getBaseURL() + "uploads/announcement/";
    this.toast = new Toaster();
  }

  mounted() {
    this.getHomepageDetails();
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  formatDate(value) {
    if (value) {
      return moment(String(value)).format("DD-MM-YYYY");
    }
  }

  formatTime(value) {
    if (value) {
      const time = moment.duration(value);
      return moment(String(time), "HH:mm:ss").format("hh:mm A");
    }
  }

  getHomepageDetails()
  {
    this.homepageTxn.getHompageDetails(this.searchForm).then((res) => {
      const d = this.camelizeKeys(res);
      this.date1                 =  d.date1;
      this.date2                 =  d.date2;
      this.announcements         =  d.announcements;
      this.activeType            =  d.activeType;
      this.customersVisitings    =  d.customersVisitings;
      this.departLabel           =  d.departLabel;
      this.departTotals          =  d.departTotals;
      this.actualData            =  d.actualData;
      this.planData              =  d.planData;
      this.branchName            =  d.branchName;
      this.topServicesLabel      =  d.topServices[0];
      this.topServicesAmount     =  d.topServices[1];
      this.getTopDepartment      =  d.getTopDepartment;
      this.announceToday         =  d.announceToday;
      this.avgValue              =  d.dashData[0].avgValue;
      this.avgValueMtd           =  d.dashData[0].avgValueMtd;
      this.buybackAmount         =  d.dashData[0].buybackAmount;
      this.buybackAmountMtd      =  d.dashData[0].buybackAmountMtd;
      this.dryCleaning           =  d.dashData[0].dryCleaning;
      this.dryCleaningMtd        =  d.dashData[0].dryCleaningMtd;
      this.getEmployeeHrs        =  d.dashData[0].getEmployeeHrs;
      this.getItemsPending       =  d.dashData[0].getItemsPending;
      this.getItemsRack          =  d.dashData[0].getItemsRack;
      this.getItemsReady         =  d.dashData[0].getItemsReady;
      this.getTotalEmployee      =  d.dashData[0].getTotalEmployee;
      this.getTotalLabourCostMtd =  d.dashData[0].getTotalLabourCostMtd;
      this.leftToday             =  d.dashData[0].leftToday;
      this.onTime                =  d.dashData[0].onTime;
      this.onTimeMtd             =  d.dashData[0].onTimeMtd;
      this.redoInvoice           =  d.dashData[0].redoInvoice;
      this.redoInvoiceMtd        =  d.dashData[0].redoInvoiceMtd;
      this.refundAmount          =  d.dashData[0].refundAmount;
      this.refundAmountMtd       =  d.dashData[0].refundAmountMtd;
      this.rushService           =  d.dashData[0].rushService;
      this.rushServiceMtd        =  d.dashData[0].rushServiceMtd;
      this.salesQtr              =  d.dashData[0].salesQtr;
      this.salesYtdly            =  d.dashData[0].salesYtdly;
      this.storeMagic            =  d.dashData[0].storeMagic;
      this.storeMagicMtd         =  d.dashData[0].storeMagicMtd;
      this.totalInvAmt           =  d.dashData[0].totalInvAmt;
      this.totalInvAmtMtd        =  d.dashData[0].totalInvAmtMtd;
      this.totalLabourCost       =  d.dashData[0].totalLabourCost;
    });
  }

  get serviceChartData() {
    return {
      labels: this.topServicesLabel,
      datasets: [
        {
          label: "Service Sold $",
          backgroundColor: '#004C97',
          yAxisID: "y-axis-1",
          data: this.topServicesAmount,
        }
      ],
    }
  }
  
  get visitings() {
    return {
      labels: ['Morning','Afternoon','Evening'],
      datasets: [
        {
          label: "Customer Visits",
          backgroundColor: '#ffc107',
          yAxisID: "y-axis-1",
          data: this.customersVisitings,
        }
      ],
    }
  }
  
  get departmentChart() {
    return {
      labels: this.departLabel,
      datasets: [
        {
          label: "Department Sold $",
          backgroundColor: '#28a745',
          yAxisID: "y-axis-1",
          data: this.departTotals,
        }
      ],
    }
  }

  get multiAxisData()
  {
    return {
      labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      datasets: [
        {
          label: "Planned Sales ($)",
          backgroundColor: '#004C97',
          yAxisID: "y-axis-1",
          data: this.planData,
        },
        {
          label: "Actual Sales ($)",
          backgroundColor: '#ffc107',
          yAxisID: "y-axis-2",
          data: this.actualData,
        },
      ],
    };
  }

  formatAmount(value) {
    value = Number(value);

    let balance = 0;

    if (value != "") {
      balance = value.toFixed(2);
    }
    return balance;
  }

  getAnnouncemnetIcon(icon) {
    let notification = "";

    if (icon == "Promotional") {
      notification = "promotion.png";
    } else if (icon == "Informative") {
      notification = "info.png";
    } else if (icon == "News") {
      notification = "news.png";
    } else {
      notification = "alert.png";
    }

    return notification;
  }

  checkTimePassed(dateAgo)
  {
    return moment(dateAgo,'YYYY-MM-DD HH:mm:ss').fromNow(true)+' ago';
  }

  openSearchForm()
  {
    this.searchDialog = true;

    this.homepageTxn.getBranchBasic().then((data) => {
      this.myStores = data.branches_list;
    });
  }

  searchDashboard()
  {
    this.getHomepageDetails();
    this.searchDialog = false;
  }
}
</script>

<style scoped>
* {
  margin-bottom: 0;
}
.dash-icons-style {
  width: 70%;
}
.dash-heading {
  display: block;
  font-size: 14px;
}

.dash-anount {
  display: block;
  font-size: 24px;
}

.dash-percentage {
  color: #c00;
}
.dash-percentage-up {
  color: green;
}
.dash-seperator {
  border-bottom: 1px solid #ccc;
  padding: 1px;
}
.section {
  background-color: #fdfdfd;
}
.decorate-sec {
  border: 1px solid #ccc;
  margin: 20px;
}

.card {
  background-color: #fff;
  border: none;
  position: relative;
  box-shadow: 0 2px 17px 2px rgb(0, 0, 0, 0.04);
}

.card {
  box-shadow: 0px 1px 10px 1px rgb(0, 0, 0, 0.04);
  border-radius: 5px;
  margin: 0;
}

.card .card-header,
.card .card-footer,
.card .card-body {
  background-color: transparent;
  border: 1px solid #eee;
}

.card .card-body {
  padding: 30px;
}

.card .card-footer {
  background-color: transparent;
  border: none;
  padding: 15px 30px;
}

.dash-top-row {
  padding: 10px 0px 5px 0px;
}

.primary-color {
  color: #004c97;
  font-weight: bold;
  font-size: 18px;
}

.text-muted {
  color: #b0b6b7 !important;
}

.float-left {
  float: left;
}

.pull-right {
  float: right;
}

.list-unstyled {
  list-style: none;
  margin: 0;
  padding: 0;
}

.text-primary {
  color: #004c97 !important;
}

.row {
  margin-left: 0px;
  margin-right: 0px;
}
</style>
