<template>
  <section>
    <div class="app-container">
      <Toolbar>
        <template v-slot:left>
          <Breadcrumb
            :home="home"
            :model="items"
            class="p-menuitem-text p-p-1"
          />
        </template>

        <template v-slot:right>
          <div class="p-inputgroup">
            <InputText
              v-model.trim="keyword"
              required="true"
              :class="{ 'p-invalid': submitted && !keyword }"
              placeholder="Search"
            />
            <Button
              icon="pi pi-search "
              class="p-button-primary p-mr-1"
              @click="loadSearchData"
            />
          </div>
          <div class="p-mx-2">
            <Button
              icon="pi pi-plus"
              class="p-button-success"
              @click="openDialog"
            />
          </div>
        </template>
      </Toolbar>
      <div class="p-mt-2">
        <DataTable
          :value="lists"
          :lazy="true"
          :paginator="checkPagination"
          :rows="limit"
          :loading="loading"
          :totalRecords="totalRecords"
          :scrollable="true"
          @page="onPage($event)"
          class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
        >
          <template #empty>
            <div class="p-text-center p-p-3">No records found</div>
          </template>
          <Column field="discount_name" header="Coupon Name"></Column>
          <Column field="discount_barcode" header="Discount Barcode"></Column>
          <Column field="discount_type" header="Coupon Type"></Column>
          <Column field="discount_percentage" header="Percentage(%)"></Column>
          <Column field="description" header="Description"></Column>
          <Column field="starts_date" header="Starts From"></Column>
          <Column field="end_date" header="Expire On"></Column>
          <Column field="status" header="Status"></Column>
          <Column :exportable="false" header="Action">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-primary p-mr-2"
                @click="editIem(slotProps.data)"
              />
              <Button
                v-if="slotProps.data.status == 'Active'"
                icon="pi pi-eye-slash"
                class="p-button-rounded p-button-danger"
                @click="confirmChangeStatus(slotProps.data, 'Inactive')"
              />
              <Button
                v-else-if="slotProps.data.status == 'Inactive'"
                icon="pi pi-eye"
                class="p-button-rounded p-button-warning"
                @click="confirmChangeStatus(slotProps.data, 'Active')"
              />
            </template>
          </Column>
        </DataTable>
      </div>

      <Dialog
        v-model:visible="productDialog"
        :style="{ width: '50vw' }"
        :maximizable="true"
        position="top"
        class="p-fluid"
      >
        <template #header>
          <h4 class="p-dialog-titlebar p-dialog-titlebar-icon">
            {{ dialogTitle }}
          </h4>
        </template>
        <div class="p-field">
          <label for="name">Coupon Name</label>
          <InputText
            id="Coupon Name"
            v-model.trim="product.name"
            required="true"
            autofocus
            :class="{ 'p-invalid': submitted && !product.name }"
          />
          <small class="p-invalid" v-if="submitted && !product.name"
            >Coupon Name is required.</small
          >
        </div>
        <div class="p-field">
          <label for="name">Scan Barcode</label>
          <InputText id="scanbarcode" v-model.trim="product.barcode" />
        </div>
        <div class="p-field">
          <div class="p-grid">
            <div class="p-col-4">
              <p>Coupon For</p>
            </div>
            <div class="p-col-4">
              <div class="p-field-radiobutton">
                <RadioButton
                  @change="clearFields"
                  name="radio1"
                  value="All"
                  v-model="product.copounfor"
                />
                <label for="city1">All</label>
              </div>
            </div>
            <div class="p-col-4">
              <div class="p-field-radiobutton">
                <RadioButton
                  @change="clearFields"
                  name="radio1"
                  value="Services"
                  v-model="product.copounfor"
                />
                <label for="city1">Services</label>
              </div>
            </div>
          </div>
        </div>
        <div class="p-field" v-if="product.copounfor == 'Services'">
          <label for="service">Services:</label>
          <Dropdown
            v-model="product.services"
            :options="allServices"
            optionLabel="name"
          />
        </div>
        <div class="p-field">
          <label for="name">Percentage(%)</label>
          <InputNumber
            id="percentage"
            :useGrouping="false"
            mode="decimal"
            :minFractionDigits="1"
            v-model="product.percentage"
          />
        </div>
        <div class="p-field">
          <label for="name">Description</label>
          <InputText id="description" v-model.trim="product.description" />
        </div>
        <div class="p-field">
          <label class="form-label" for="name">Start From</label>
          <input class="form-control" type="date" v-model="product.startdate" />
        </div>

        <div class="p-field">
          <label class="form-label" for="name">End From</label>
          <input class="form-control" type="date" v-model="product.enddate" />
        </div>

        <template #footer>
          <Button
            type="submit"
            label="Save"
            icon="pi pi-check"
            class="p-button-primary"
            @click="saveItem"
          />
        </template>
      </Dialog>
      <Dialog
        v-model:visible="statusDialog"
        :style="{ width: '450px' }"
        header="Confirm"
      >
        <div class="confirmation-content">
          <i
            class="pi pi-exclamation-triangle p-mr-3"
            style="font-size: 2rem"
          />
          <span v-if="product">
            Change the status of <b>{{ product.name }}</b> to
            {{ productStatus }}?</span
          >
        </div>
        <template #footer>
          <Button
            label="No"
            icon="pi pi-times"
            class="p-button-text"
            @click="statusDialog = false"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            class="p-button-text"
            @click="changeStatus"
          />
        </template>
      </Dialog>
    </div>
  </section>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Coupon from "../../service/Coupon";
import Toaster from "../../helpers/Toaster";

@Options({
  components: {},
})
export default class Coupons extends Vue {
  private imagePath = "";
  private lists = [];
  private allServices;
  private coupon;
  private keyword = "";
  private productStatus = "";
  private loading = false;
  private checkPagination = true;
  private productDialog = false;
  private statusDialog = false;
  private totalRecords = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Coupon" },
  ];

  private itemTypes = [{ key: "Product" }, { key: "Service" }];

  private popupConditions = [{ key: "No" }, { key: "Yes" }];

  private product = {
    id: 0,
    name: "",
    barcode: "",
    copounfor: "",
    percentage: 0,
    description: "",
    services: {},
    startdate: "",
    enddate: "",
    status: "",
  };

  private dialogTitle;
  private dialogCallback;
  private submitted = false;

  private serviceId = "";
  private serviceObj;

  private departmentId = "";
  private departmentObj;

  private selectedPopConditionKey = "";
  private selectedPopConditionObj;

  private selectedItemTypeKey = "";
  private selectedItemTypeObj;
  private toast;

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  // CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }

  created() {
    this.coupon = new Coupon();
    this.imagePath = this.coupon.getBaseURL() + "uploads/services/";
    this.toast = new Toaster();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.product = {
      id: 0,
      name: "",
      barcode: "",
      copounfor: "",
      services: {},
      percentage: 0,
      description: "",
      startdate: "",
      enddate: "",
      status: "",
    };

    this.submitted = false;
    this.dialogTitle = "Add Coupon";
    this.productDialog = true;
  }

  //CLOSE THE ITEM DAILOG BOX
  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(e) {
    e.preventDefault();
    this.submitted = true;
    if (this.product.name.trim()) {
      if (this.product.id != 0) {
        this.coupon.updateItem(this.product).then((res) => {
          this.loadList(0);
          this.toast.handleResponse(res);
        });
      } else {
        this.coupon.saveItem(this.product).then((res) => {
          this.loadList(0);
          this.toast.handleResponse(res);
        });
      }

      this.productDialog = false;
      this.product = {
        id: 0,
        name: "",
        barcode: "",
        copounfor: "",
        services: {},
        percentage: 0,
        description: "",
        startdate: "",
        enddate: "",
        status: "",
      };
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.submitted = false;
    this.dialogTitle = "Edit Coupon";
    this.productDialog = true;

    this.coupon.getItem(data).then((res) => {
      if (res.length > 0) {
        console.log(res);
        let selectedService;
        this.allServices.filter((elem) => {
          if (elem.id == res[0].service_id) {
            selectedService = elem;
          }
        });

        this.product = {
          id: res[0].id,
          name: res[0].discount_name,
          barcode: res[0].discount_barcode,
          copounfor: res[0].discount_type,
          services: selectedService,
          percentage: res[0].discount_percentage,
          description: res[0].description,
          startdate: res[0].starts_date,
          enddate: res[0].end_date,
          status: res[0].status,
        };
      }
    });
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmChangeStatus(data, status) {
    this.product = {
      id: data.id,
      name: data.discount_name,
      barcode: "",
      copounfor: "",
      services: {},
      percentage: 0,
      description: "",
      startdate: "",
      enddate: "",
      status: data.status,
    };
    this.productStatus = status;
    this.statusDialog = true;
  }

  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  changeStatus() {
    this.statusDialog = false;
    this.product.status = this.productStatus;
    this.coupon.changeStatus(this.product).then((res) => {
      this.loadList(0);
      this.toast.handleResponse(res);
    });
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.loading = true;
    this.coupon.getItems(page).then((data) => {
      this.lists = data.discount_list;
      this.totalRecords = data.total_coupons_count;
      this.limit = data.limit_per_page;
      this.allServices = data.services;
      this.loading = false;
    });
  }

  loadSearchData() {
    this.submitted = true;
    if (this.keyword) {
      this.coupon.getSearchedCoupons(this.keyword).then((data) => {
        this.lists = data.discount_list;
        this.allServices = data.services;
        this.checkPagination = data.pagination;
      });
    }
  }

  clearFields() {
    this.product.percentage = 0;
    this.product.description = "";
    this.product.services = {};
    this.product.startdate = "";
    this.product.enddate = "";
  }
}
</script>

<style scoped>
.product-image {
  width: 30px;
  height: auto;
}
</style>