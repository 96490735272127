
import { Options, Vue } from "vue-class-component";
import DryCleaner from "../../service/DryCleaner";
import Toaster from "../../helpers/Toaster";

@Options({
  components: {},
})
export default class DryCleaners extends Vue {
  private imagePath = "";
  private lists = [];
  private drycleaners;
  private allRegions;
  private productStatus = "";
  private keyword = "";
  private checkPagination = true;
  private productDialog = false;
  private statusDialog = false;
  private totalRecords = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Dry Cleaners" },
  ];
  private toast;

  private product = {
    id: 0,
    name: "",
    percentage: "",
    phone: "",
    email: "",
    region: "",
    address: "",
    city: "",
    description: "",
    status: "",
  };

  private selectedRegion;
  private selectedRegionObj;

  private dialogTitle;
  private dialogCallback;
  private submitted = false;

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.drycleaners = new DryCleaner();
    this.toast = new Toaster();
    this.imagePath = this.drycleaners.getBaseURL() + "uploads/services/";
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.product = {
      id: 0,
      name: "",
      percentage: "",
      phone: "",
      email: "",
      region: "",
      address: "",
      city: "",
      description: "",
      status: "",
    };

    this.submitted = false;
    this.dialogTitle = "Add New Region";
    this.productDialog = true;
  }

  //CLOSE THE ITEM DAILOG BOX
  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(e) {
    e.preventDefault();

    this.submitted = true;
    if (this.product.name.trim()) {
      if (this.product.id != 0) {
        this.drycleaners.updateItem(this.product).then((res) => {
          this.loadList(0);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      } else {
        this.drycleaners.saveItem(this.product).then((res) => {
          this.loadList(0);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      }

      this.productDialog = false;
      this.product = {
        id: 0,
        name: "",
        percentage: "",
        phone: "",
        email: "",
        region: "",
        address: "",
        city: "",
        description: "",
        status: "",
      };
    }
  }

  //SET DROPDOWN DATA IN EDIT FORM
  setDropDownData() {
    this.allRegions.filter((elem) => {
      if (elem.region_id == this.selectedRegion) {
        this.selectedRegionObj = elem;
      }
    });
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.submitted = false;
    this.dialogTitle = "Update Dry Cleaner";
    this.productDialog = true;
    this.drycleaners.getItem(data).then((res) => {
      if (res.length > 0) {
        this.selectedRegion = res[0].region_id;
        this.setDropDownData();

        this.product = {
          id: res[0].dry_clearner_id,
          name: res[0].name,
          percentage: res[0].percentage,
          phone: res[0].phone,
          email: res[0].email,
          region: this.selectedRegionObj,
          address: res[0].address,
          city: res[0].city,
          description: res[0].description,
          status: res[0].status,
        };
      }
    });
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmChangeStatus(data, status) {
    this.product = {
      id: data.dry_clearner_id,
      name: data.name,
      percentage: "",
      phone: "",
      email: "",
      region: "",
      address: "",
      city: "",
      description: "",
      status: data.status,
    };
    this.productStatus = status;
    this.statusDialog = true;
  }

  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  changeStatus() {
    this.statusDialog = false;
    this.product.status = this.productStatus;
    this.drycleaners.changeStatus(this.product).then((res) => {
      this.loadList(0);
      //SHOW NOTIFICATION
      this.toast.handleResponse(res);
    });
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.drycleaners.getItems(page).then((data) => {
      this.lists = data.dry_cleaners;
      this.allRegions = data.regions;
      this.totalRecords = data.total_drycleaner_count;
      this.limit = data.limit_per_page;
    });
  }

  loadSearchData() {
    this.submitted = true;
    if (this.keyword) {
      this.drycleaners.getSearchedDryCleaners(this.keyword).then((data) => {
        this.lists = data.dry_cleaners;
        this.allRegions = data.regions;
        this.checkPagination = data.pagination;
      });
    }
  }
}
