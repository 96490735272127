
import { Options, Vue } from "vue-class-component";
import CorporateBranch from "../../service/CorporateBranch";
import Toaster from "../../helpers/Toaster";

@Options({
  components: {},
})
export default class CorporateBranches extends Vue {
  private lists = [];
  private CorporateBranch;
  private allSchedule;
  private allCorp;
  private productStatus = "";
  private keyword = "";
  private productDialog = false;
  private checkPagination = true;
  private statusDialog = false;
  private totalRecords = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Corporate Branch" },
  ];
  private toast;

  private corporateData = {
    id: 0,
    corpHead: {},
    title: "",
    address: "",
    contact: "",
    email: "",
    description: "",
    fax: "",
    accounttype: {},
    schedule: {},
    empNo: "",
    poNo: "",
    reqForm: "",
    extraInfo: [{ fieldname: "" }],
    status: "",
  };

  private accountTypes = [{ key: "Charge" }, { key: "COD" }];

  private dialogTitle;
  private dialogCallback;
  private submitted = false;

  // ADD ADDITIONAL INPUT FIELDS
  addField() {
    this.corporateData.extraInfo.push({
      fieldname: "",
    });
  }

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.CorporateBranch = new CorporateBranch();
    this.toast = new Toaster();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.clearItem();

    this.submitted = false;
    this.dialogTitle = "Create Corporate Profile";
    this.productDialog = true;
  }

  //CLOSE THE ITEM DAILOG BOX
  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(e) {
    e.preventDefault();
    this.submitted = true;
    if (this.corporateData.title.trim()) {
      if (this.corporateData.id != 0) {
        this.CorporateBranch.updateItem(this.corporateData).then((res) => {
          this.loadList(0);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      } else {
        this.CorporateBranch.saveItem(this.corporateData).then((res) => {
          this.loadList(0);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      }

      this.productDialog = false;
      this.clearItem();
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.submitted = false;
    this.dialogTitle = "Edit Corporate Profile";
    this.productDialog = true;

    this.CorporateBranch.getItem(data).then((res) => {
      if (res) {
        //FIND THE CATEGORY TYPE AND MAKE IT AS SELECTED IN EDIT DIALOG BOX.
        const setAccountTypes = this.allAccountTypesFilter(res.account_type);
        const setScheduleType = this.allScheduleFilter(res.price_list_id);
        const setCorpHeadType = this.allCorpHeadFilter(res.corporate_id);
        const setExtraInfo = this.extraInfoFilter(res.corporate_meta);

        this.corporateData.id = res.corporate_main_id;
        this.corporateData.corpHead = setCorpHeadType;
        this.corporateData.title = res.title;
        this.corporateData.address = res.address;
        this.corporateData.contact = res.contact;
        this.corporateData.email = res.email;
        this.corporateData.description = res.title;
        this.corporateData.fax = res.fax;
        this.corporateData.accounttype = setAccountTypes;
        this.corporateData.schedule = setScheduleType;
        this.corporateData.empNo = res.emp_no;
        this.corporateData.poNo = res.po_no;
        this.corporateData.reqForm = res.requisition_form;
        this.corporateData.extraInfo = setExtraInfo;
        this.corporateData.status = res.status;

      }
    });
  }

  //USED TO FILTER TYPES
  allAccountTypesFilter(res) {
    let accountTypeOpt = {};

    this.accountTypes.filter((elem) => {
      if (elem.key == res) {
        accountTypeOpt = elem;
      }
    });
    return accountTypeOpt;
  }

  //USED TO FILTER TYPES
  allScheduleFilter(res) {
    let scheduleOpt = {};

    this.allSchedule.filter((elem) => {
      if (elem.id == res) {
        scheduleOpt = elem;
      }
    });
    return scheduleOpt;
  }

  //USED TO FILTER TYPES
  extraInfoFilter(res) {
    if (res.length <= 0) {
      const extraInfo = [{ fieldname: "" }];
      return extraInfo;
    } else {
      const extraInfo = [{ fieldname: "" }];
      extraInfo.splice(0, 1);
      res.map((item) => extraInfo.push({ fieldname: item.field_name }));
      return extraInfo;
    }
  }

  //USED TO FILTER TYPES
  allCorpHeadFilter(res) {
    let corpHeadOpt = {};

    this.allCorp.filter((elem) => {
      if (elem.corporate_id == res) {
        corpHeadOpt = elem;
      }
    });
    return corpHeadOpt;
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmChangeStatus(data, status) {
    this.corporateData.id = data.corporate_main_id;
    this.corporateData.title = data.title;
    this.corporateData.status = data.status;

    this.productStatus = status;
    this.statusDialog = true;
  }

  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  changeStatus() {
    this.statusDialog = false;
    this.corporateData.status = this.productStatus;
    this.CorporateBranch.changeStatus(this.corporateData).then((res) => {
      this.loadList(0);
      //SHOW NOTIFICATION
      this.toast.handleResponse(res);
    });
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.CorporateBranch.getItems(page).then((data) => {
      this.lists = data.corporate_record;
      this.totalRecords = data.total_corporates_count;
      this.limit = data.limit_per_page;
      this.allCorp = data.corporate_parent;
      this.allSchedule = data.schedule;
    });
  }

  loadSearchData() {
    this.submitted = true;
    if (this.keyword) {
      this.CorporateBranch.getSearchedCorpBranch(this.keyword).then((data) => {
        this.lists = data.corporate_record;
        this.allCorp = data.corporate_parent;
        this.allSchedule = data.schedule;
        this.checkPagination = data.pagination;
      });
    }
  }
  clearItem() {
    this.corporateData.id = 0;
    this.corporateData.corpHead = {};
    this.corporateData.title = "";
    this.corporateData.address = "";
    this.corporateData.contact = "";
    this.corporateData.email = "";
    this.corporateData.description = "";
    this.corporateData.fax = "";
    this.corporateData.schedule = {};
    this.corporateData.empNo = "";
    this.corporateData.poNo = "";
    this.corporateData.reqForm = "";
    this.corporateData.extraInfo = [{ fieldname: "" }];
    this.corporateData.status = "";
  }
}
