
import { Options, Vue } from "vue-class-component";
import Department from "../../service/Department";
import Toaster from "../../helpers/Toaster";

@Options({
  components: {},
})
export default class Departments extends Vue {
  private imagePath = "";
  private lists = [];
  private department;
  private productStatus = "";
  private productDialog = false;
  private checkPagination = true;
  private statusDialog = false;
  private totalRecords = 0;
  private limit = 0;
  private keyword = "";
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Department" },
  ];
  private toast;
  private product = {
    id: 0,
    name: "",
    priority: 2,
    status: "",
    picture: "",
  };

  private dialogTitle;
  private dialogCallback;
  private submitted = false;

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }
  // CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }

  created() {
    this.department = new Department();
    this.imagePath = this.department.getBaseURL() + "uploads/services/";
    this.toast = new Toaster();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.product = {
      id: 0,
      name: "",
      priority: 2,
      status: "",
      picture: "",
    };

    this.submitted = false;
    this.dialogTitle = "Add New Department";
    this.productDialog = true;
  }

  //CLOSE THE ITEM DAILOG BOX
  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(e) {
    e.preventDefault();

    this.submitted = true;
    if (this.product.name.trim()) {
      if (this.product.id != 0) {
        this.department.updateItem(this.product).then((res) => {
          this.loadList(0);
          this.toast.handleResponse(res);
        });
      } else {
        this.department.saveItem(this.product).then((res) => {
          this.loadList(0);
          this.toast.handleResponse(res);
        });
      }

      this.productDialog = false;
      this.product = {
        id: 0,
        name: "",
        priority: 2,
        status: "",
        picture: "",
      };
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.submitted = false;
    this.dialogTitle = "Update Department";
    this.productDialog = true;

    this.department.getItem(data).then((res) => {
      if (res.length > 0) {
        this.product = {
          id: res[0].id,
          name: res[0].sector,
          priority: res[0].priority,
          status: res[0].status,
          picture: res[0].picture,
        };
      }
    });
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmChangeStatus(data, status) {
    this.product = {
      id: data.id,
      name: data.sector,
      priority: data.priority,
      status: data.status,
      picture: data.picture,
    };
    this.productStatus = status;
    this.statusDialog = true;
  }

  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  changeStatus() {
    this.statusDialog = false;
    this.product.status = this.productStatus;
    this.department.changeStatus(this.product).then((res) => {
      this.loadList(0);
      this.toast.handleResponse(res);
    });
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.department.getItems(page).then((data) => {
      this.lists = data.department_list;
      this.totalRecords = data.total_department_count;
      this.limit = data.limit_per_page;
    });
  }

  loadSearchData() {
    this.submitted = true;
    if (this.keyword) {
      this.department.getSearchedDepartment(this.keyword).then((data) => {
        this.lists = data.department_list;
        this.checkPagination = data.pagination;
      });
    }
  }
}
